[dir='rtl'] {
    @import '../css/bootstrap-grid.rtl.min';
    @import '../css/bootstrap-utilities.rtl.min';
    @import '../css/bootstrap-reboot.rtl.min';
}

::-webkit-scrollbar-track {
    border: 2px solid white;
    background-color: #cccccc;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
    background-color: var(--bs-blue);
    border-radius: 10px;
}

* {
    font-family: $font-family-sans-serif;
}
