$blue: #0a2459;
$indigo: #6610f2;
$purple: #8b36cf;
$pink: #d63384;
$red: #de0f79;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #00c9a7;
$teal: #20c997;
$cyan: #4477d3;
$cyan: #4477d3;
$white: #fff;
$white-2: #edeff7;
$white-3: #dbdbdb;


.bg-color-1 {
    background-color: $blue !important;
}

.bg-color-2 {
    background-color: $indigo !important;
}

.bg-color-3 {
    background-color: $purple !important;
}

.bg-color-4 {
    background-color: $pink !important;
}

.bg-color-5 {
    background-color: $red !important;
}

.bg-color-6 {
    background-color: $orange !important;
}

.bg-color-7 {
    background-color: $yellow !important;
}

.bg-color-8 {
    background-color: $green !important;
}

.bg-color-9 {
    background-color: $teal !important;
}

.bg-color-10 {
    background-color: $cyan !important;
}

.bg-color-11 {
    background-color: $white !important;
}

.bg-color-12 {
    background-color: $white-2 !important;
}

.bg-color-12 {
    background-color: $white-3 !important;
}

.color-1 {
    color: $blue;
}

.color-2 {
    color: $indigo;
}

.color-3 {
    color: $purple;
}

.color-4 {
    color: $pink;
}

.color-5 {
    color: $red;
}

.color-6 {
    color: $orange;
}

.color-7 {
    color: $yellow;
}

.color-8 {
    color: $green;
}

.color-9 {
    color: $teal;
}

.color-10 {
    color: $cyan;
}

.color-11 {
    color: $white;
}

.color-12 {
    color: $white-2;
}

// border right color
.brc-1 {
    border-right-color: $blue !important;
}

.brc-2 {
    border-right-color: $indigo !important;
}

.brc-3 {
    border-right-color: $purple !important;
}

.brc-4 {
    border-right-color: $pink !important;
}

.brc-5 {
    border-right-color: $red !important;
}

.brc-6 {
    border-right-color: $orange !important;
}

.brc-7 {
    border-right-color: $yellow !important;
}

.brc-8 {
    border-right-color: $green !important;
}

.brc-9 {
    border-right-color: $teal !important;
}

.brc-10 {
    border-right-color: $cyan !important;
}

.brc-11 {
    border-right-color: $white !important;
}

.brc-12 {
    border-right-color: $white-2 !important;
}


$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$light-2: $white-2;


$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "light-2": $light-2,
    "dark": $dark,
    "indigo": $indigo,
);

$font-family-sans-serif: 'Tajawal', sans-serif;
$form-check-input-checked-bg-color: $indigo;
$form-check-input-checked-border-color: $indigo;
$input-border-radius: 100px;
$input-bg: $light;

:root {
    --ng-select-bg: var(--bs-indigo);
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Hebrew:wght@100;200;300;400;500;600;700;800;900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import "bootstrap-icons/font/bootstrap-icons";

@import "libs/assets/scss/bootstrap";

[dir='rtl'] {
    @import 'assets/css/bootstrap-grid.rtl.min';
    @import 'assets/css/bootstrap-utilities.rtl.min';
    @import 'assets/css/bootstrap-reboot.rtl.min.css';
    --bs-body-bg: #edeff7;
}



::-webkit-scrollbar-track {
    border: 2px solid white;
    background-color: #cccccc;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb {
    background-color: var(--bs-blue);
    border-radius: 10px;
}

* {
    font-family: $font-family-sans-serif;
}


.form-label {
    font-weight: 500;
}

.title1 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.title2 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--bs-purple);
}

[dir="rtl"] {
    .custom-control {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;
    }

    .custom-control-label::before {
        top: 0.25rem !important;
        right: -1.5rem !important;
    }

    .custom-control-label::after {
        top: 0.25rem !important;
        right: -1.5rem !important;
    }
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: var(--bs-primary);
    background-color: var(--bs-primary);
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #e5657440 !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff !important;
    background-color: #aa4b56 !important;
    border-color: #e56574 !important;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #e56574 !important;
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-radius: 19px 19px 0px 0px;
}

.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
}

.cdk-drag-placeholder {
    opacity: 0.5;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-box:last-child {
    border: none;
}

.cdk-drag-list.cdk-drop-list-dragging .cdk-drag-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
